let sitefinityUrl = '';

const hostname = window.location.hostname;

if (hostname.includes('localhost')) {
  sitefinityUrl = '';
} else if (hostname.includes('dev.tkmaxx.com.au')) {
  sitefinityUrl = 'https://dev.cms.tkmaxx.com.au';
}  else if (hostname.includes('stage.tkmaxx.com.au')) {
  sitefinityUrl = 'https://stage.cms.tkmaxx.com.au';
} else if (hostname.includes('uat.tkmaxx.com.au')) {
  sitefinityUrl = 'https://uat.cms.tkmaxx.com.au';
} else if (hostname.includes('tkmaxx.com.au')) {
  sitefinityUrl = 'https://cms.tkmaxx.com.au';
} else {
  sitefinityUrl = 'https://cms.tkmaxx.com.au'; // Default to production if unknown environment
}

export default sitefinityUrl;